$ = require 'jquery'

$ ->
  $('.main-content__header__left').click ->
    $('.main-content__login').hide()
    $('.main-content__copy').show()

  $('.client-login').click ->
    $('.main-content__login').toggle()
    $('.main-content__copy').toggle()
